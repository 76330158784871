<template>
  <div class="footer--find">
    <div class="footer--title mb-3">
      {{ $t('footer.find.header') }}
    </div>
    <div class="d-flex flex-wrap mb-3">
      <router-link to="/directory/rumah" class="footer--nav mb-2">
        {{ $t('footer.find.house') }}
      </router-link>
      <router-link to="/directory/gudang" class="footer--nav mb-2">
        {{ $t('footer.find.warehouse') }}
      </router-link>
      <router-link to="/directory/apartment" class="footer--nav mb-2">
        {{ $t('footer.find.apartment') }}
      </router-link>
      <router-link to="/directory/kantor" class="footer--nav mb-2">
        {{ $t('footer.find.office') }}
      </router-link>
      <router-link to="/directory/ruko" class="footer--nav mb-2">
        {{ $t('footer.find.shophouse') }}
      </router-link>
      <router-link to="/directory/coworkingspace" class="footer--nav mb-2">
        {{ $t('footer.find.co-working') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
